:root {
  --primary-color: black;
  --secondary-color: white;
  --third-color: #8a1f9b;
  --thirdDim-color: #ac49e194;
  --span-color: rgb(69, 69, 69);
}

.ant-btn-primary {
  background-color: var(--third-color) !important;
  border-color: var(--third-color) !important;
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");

@keyframes typewriter {
  0% {
    width: 0;
  }
  70% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

.spanClass {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--span-color);
  gap: 0.5rem;
  font-size: small;
}

h1 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  color: var(--third-color);
}

h2,
h3 {
  font-weight: 600;
  font-style: normal;
  color: var(--third-color);
}

* {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
}

b,
h4 {
  font-weight: 800 !important;
}

@keyframes blink {
  0% {
    border-right-color: black;
  }
  50% {
    border-right-color: transparent;
  }
  100% {
    border-right-color: black;
  }
}

/* .glassmorph {
  background: rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
}

.glassmorph2 {
  background: rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
} */

.neomorphism {
  background: rgba(255, 255, 255, 0.48) !important;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #bebebe;
  border-radius: 10px;
}

.navbar {
  padding: 0.5rem 2rem;
  margin: 1rem auto;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  border-radius: 10px;
  display: flex;
  width: 90%;
  /* background-color: rgba(207, 217, 223, 0.5); */
  background-color: var(--secondary-color);
  z-index: 100;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
}

.navbar_right {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.navbar2 {
  padding: 1rem 2rem;
  margin: 1rem auto;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  border-radius: 10px;
  display: flex;
  width: 80%;
  z-index: 100;
  box-sizing: border-box;
  left: 0;
  right: 0;
}

.navbar_options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.navbar_options a {
  padding: 0.5rem;
  font-size: larger;
}

.ant-drawer-close .anticon.anticon-close,
.ant-drawer-title {
  color: var(--third-color);
  font-size: 22px !important;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.navbar_options a:hover {
  background-color: var(--third-color);
  border-radius: 10px;
  color: white !important;
}

a {
  text-decoration: none;
  color: black;
}

body {
  background-image: linear-gradient(
    to top,
    #f3e7e9 0%,
    #e3eeff 99%,
    #e3eeff 100%
  );

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.pageContainer {
  /* padding: 7rem 0 !important; */
  width: 90%;
  padding: 0 1rem;
  margin: 0 auto;
  min-height: 100%;
  overflow-y: scroll;

  /* background-color: var(--primary-color); */
  box-sizing: border-box;
}

.pageContainer2 {
  padding-top: 8rem !important;
  padding-bottom: 3rem !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
  /* background-color: var(--primary-color); */
  box-sizing: border-box;
}

.loginFormOuterContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-image: url("./assets/images/bg5.jpg"); */
  background-image: linear-gradient(
    to top,
    #f3e7e9 0%,
    #e3eeff 99%,
    #e3eeff 100%
  );
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.loginFormInnerContainer .ant-tabs-nav {
  margin: auto !important;
}

.logo {
  aspect-ratio: 1;
  width: 30%;
  /* mix-blend-mode: luminosity; */
}

.logo2 {
  aspect-ratio: 1;
  width: 15%;
  /* mix-blend-mode: luminosity; */
}

.loginFormInnerContainer {
  max-height: 70vh;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 3rem;
  box-sizing: border-box;
}

.loginFormInnerContainer_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.loginFormInnerContainer .ant-tabs {
  width: 100% !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--third-color) !important;
}

.pageContainer .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--secondary-color) !important;
}

.loginFormInnerContainer .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}

.loginFormInnerContainer .ant-tabs-tab-btn {
  font-size: larger;
  font-weight: 700;
}

.pageContainer .ant-tabs-tab-btn {
  color: var(--span-color) !important;
}

.pageContainer .ant-tabs-tab-btn {
  color: var(--span-color) !important;
}

.pageContainer .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--third-color);
}

.pageContainer .ant-tabs-tab {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0.5rem 2rem;
  margin: 0 !important;
}

.pageContainer .ant-tabs-nav {
  width: max-content !important;
}

.pageContainer .ant-tabs .ant-tabs-ink-bar {
  background-color: var(--third-color);
}

.loginFormInnerContainer .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.ant-table-thead tr th {
  background-color: transparent !important;
}

.ant-table-tbody tr td {
  background-color: rgba(255, 255, 255, 0.245);
}

.ant-table-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

/* 
.ant-table-tbody tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ant-table-tbody tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ant-table-cell {
  border-bottom: none !important;
}

.ant-table-content table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0 1rem;
  background-color: none;
}

.ant-table-content table tr th:first-child {
  border-bottom-left-radius: 10px;
}

.ant-table-content table tr th:last-child {
  border-bottom-right-radius: 10px;
} */

.errorText {
  color: red;
  text-align: center;
}

.buttonSubmit {
  background-color: #acd793;
  padding: 0.5rem;
  border-radius: 20px;
  color: white;
  transition: transform 0.3s ease, color 0.3s ease;
}

button:hover {
  transform: scale(1.1);
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
}

.inputLabel {
  display: flex;
  flex-direction: column;
}

.loginRow2 {
  display: flex;
  justify-content: space-between;
}

.loginRow2 span {
  font-size: x-small;
  cursor: pointer;
}

.loginRow2 span:hover {
  color: red;
}

.inputLabel input {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid;
}

.inputLabel select {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid;
}

.navbar-registerUser {
  height: 10vh;
}

.registrationForm h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.conferenceDetail_row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conferenceDetail_row1_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.fullPageLoading {
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.createConferencePage h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.conferenceLink {
  color: blue;
}

.emailTemplate_placeHolders {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.emailTemplate_inputDiv {
  display: flex;
  gap: 10px;
  margin: 1rem 0;
  align-items: center;
}

.emailTemplate_placeHolders_info p {
  font-size: x-small;
  font-style: italic;
  color: gray;
}

.emailTemplate_placeHolders_tag {
  background-color: #f0f0f0;
  padding: 0 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.emailTemplate_placeHolders_tag:hover {
  background-color: #69b1ff;
  color: white;
}

.paperDetails {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.paperDetails_row1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.newReview_row1_detail {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0.5rem 0;
}

.paperDetails_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.templateInput {
  text-align: center;
  font-size: xx-large;
  width: 50%;
}

.conferenceSettings_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_container {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100vh;
}

.loginFormInner {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.loginleft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 0;
  align-items: center;
  gap: 1rem;
  /* background-image: url("../images/loginbg.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 1rem;
}

.login_container_row1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.loginInput {
  padding: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

.manageReviewers_row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
